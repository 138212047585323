import config from './config.json'

import http from './httpService'
import axios from 'axios';

export const addBankAccount = account => {
    return http.post(`${config.coinbaseapi}/accounts`, JSON.stringify(account))
}

export const getBankAccount = () => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
    return http.get(`${config.coinbaseapi}/accounts`)
}

export const deleteBankAccount = id => {
    return http.delete(`${config.coinbaseapi}/accounts/${id}`)
}
export const editBankAccount = (id, obj) => {
    return http.patch(`${config.coinbaseapi}/accounts/${id}`, obj)
}

export const getAccountsActice = () => {
    return http.get(`${config.coinbaseapi}/accounts?status=accepted`)
}