import React, {useState, useEffect} from 'react'
import InfiniteScroll from 'react-infinite-scroller';
import Loading from "../Loading/Loading";
import {convertDateTime, numberWithDash} from "../../utils/Price";
import {getStatusTransactionCrypto} from "../../utils/helperFunction";
import {useDispatch, useSelector} from "react-redux";
import {getDataTrnsactionTomanHistory} from "../../redux/actions/historyLoginActions";
import { numberWithCommas } from './../../utils/Price';

const HistoryTransactionToman = ({filterString}) => {

    const historyTransactionToman = useSelector(state => state.historyTransactionToman)
    const metaTransactionToman = useSelector(state => state.metaTransactionToman)

    const dispatch = useDispatch()
    const [lastPage] = useState(metaTransactionToman.last_page)
    const [hasMore, setHasMore] = useState(true)

    const [getFilterString, setFilterString] = useState(filterString)


    const handleNextData = (page) => {

        if (page <= lastPage) {
            dispatch(getDataTrnsactionTomanHistory(`${filterString}&page=${page}`))
        } else {
            setHasMore(false)
        }

    }

    useEffect(() => {
        setFilterString(filterString)
    }, [filterString]);

    return (
        <InfiniteScroll
            hasMore={hasMore}
            pageStart={0}
            loader={<Loading/>}
            loadMore={() => handleNextData(metaTransactionToman.current_page + 1)}
        >
            <table dir='rtl' className='table table-borderless'>
                <thead>
                <tr>
                    <td className='titleTableEntires text-right'>عملیات</td>
                    <td className='titleTableEntires text-center'>مقدار درخواستی (تومان)</td>
                    <td className='titleTableEntires text-center'><span className='noWrap'>شماره کارت</span></td>
                    <td className='titleTableEntires text-center'>وضعیت</td>
                    <td className='titleTableEntires text-center'>شماره پیگیری</td>
                    <td className='titleTableEntires text-center'>زمان</td>
                </tr>
                </thead>
                <tbody>
                {historyTransactionToman.length > 0 ?
                    historyTransactionToman.map((item, index) => (
                        <tr key={index}>
                            <td className='text-right'><span
                                className={item.type === 'DEPOSIT' ? 'colorGreen valueTableEntires' : 'colorRed valueTableEntires'}>{item.type === 'DEPOSIT' ? 'واریز' : 'برداشت'}</span>
                            </td>
                            <td className='text-center'><span
                                className='valueTableEntires'>{numberWithCommas(item.amount)}</span></td>
                            <td className='text-center'><span
                                className='valueTableEntires'>{item.account ? numberWithDash(item.account.card_number) : '-'}</span>
                            </td>
                            <td className='text-center'><span
                                className={item.status==='REJECTED' ? 'colorRed valueTableEntires' : 'colorGreen valueTableEntires'}>{item.status ? getStatusTransactionCrypto(item.status):''}</span>
                            </td>
                            <td className='text-center'><span
                                className='valueTableEntires'>{item.payment ? item.payment.ref_number : '-'}</span>
                            </td>
                            <td className='text-center dateTimeInFAQ'><span
                                className='valueTableEntires'>{convertDateTime(item.created_at)}</span>
                            </td>
                        </tr>
                    ))
                    :
                    <p>اطلاعاتی موجود نیست</p>}

                </tbody>
            </table>
        </InfiniteScroll>
    )
}
export default HistoryTransactionToman
