import React, {useRef} from 'react';
import {DialogOverlay, DialogContent} from '@reach/dialog';

import './dialogBuySell.scss'
import {buyMode, sellMode} from './../../redux/actions/sellBuyAcions';
import {useDispatch, useSelector} from 'react-redux';
import SellModeModal from '../../components/SellModeModal/SellModeModal';
import Step1Buy from "../../components/Step1Buy/Step1Buy";


const DialogBuySell = ({showDialog, closeDialog, fromNav, id}) => {

    const buy = useSelector(state => state.buy)

    const refBar = useRef()
    const dispatch = useDispatch()

    return (
        <DialogOverlay
            isOpen={showDialog}
            onDismiss={closeDialog}
            style={{background: "hsla(0,100%,100%,0.9)"}}>
            <DialogContent  className='containerNewQuestion'>

                <div className='insideDialog'>
                    <div className='boxBtnSellBuy boxBtnSellBuyDialog row'>
                        <div className='col-6 boxBtn' onClick={() => dispatch(sellMode())}>
                            <p style={{color: buy ? '#828282' : ' #4F4F4F'}} className='pBtnBuySell'>فروش به ما</p>
                        </div>
                        <div className='col-6 boxBtn' onClick={() => dispatch(buyMode())}>
                            <p style={{color: buy ? '#4F4F4F' : ' #828282'}} className='pBtnBuySell'>خرید از ما</p>
                        </div>
                        <div ref={refBar} className={`barBelowBtn ${!buy ? 'moveLeft' : ''}`}/>
                    </div>
                    {buy ?
                        <Step1Buy closeFunction={closeDialog}/>
                        :
                        <SellModeModal closeFunction={closeDialog}/>
                    }

                </div>
            </DialogContent>
        </DialogOverlay>
    );
}

export default DialogBuySell;