import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Formik} from 'formik';
import * as Yup from 'yup'
import useForceUpdate from 'use-force-update';


import './addDebitCard.scss'
import ErrorMessage from './../ErrorMessage/ErrorMessage';
import {addBankAccount} from './../../services/bankAccountService';
import {toast} from 'react-toastify';
import {getAccounts} from './../../redux/actions/bankAccountsAction';
import CustomInput from "../CustomInput/CustomInput";
import {Spinner} from "reactstrap";
import InputCardNumber from "../InputCardNumber/InputCardNumber";

const validationSchema = Yup.object().shape({
    sheba: Yup.string()
        .required('پر کردن این فیلد الزامی می باشد.')
        .matches(/^[0-9]+$/, "فقط باید شامل اعداد باشد.")
        .min(24, 'نباید کمتر از 24 کراکتر باشد')
        .max(24, 'نباید بیشتر از 24 کاراکتر باشد'),
    card_number: Yup.string()
        .required('پر کردن این فیلد الزامی می باشد.')
        .matches(/^[0-9]+$/, "فقط باید شامل اعداد باشد.")
        .min(16, 'نباید کمتر از 16 کراکتر باشد')
        .max(16, 'نباید بیشتر از 16 کاراکتر باشد')
})


const AddDebitCard = () => {

    const dispatch = useDispatch()
    const [sheba, setSheba] = useState('')
    const [card_number, setCard_number] = useState('')
    const [loading, setLoading] = useState(false)
    const initialValues = {sheba: sheba, card_number: card_number};
    const forceUpdate = useForceUpdate();


    const handleAddCardDebit = async (values, resetForm, setFieldValue) => {
        try {
            setLoading(true)
            const {data} = await addBankAccount(values)

            if (data.type === 'success') {
                toast.success(`${data.message}`, {position: 'top-right', closeOnClick: true})
                setCard_number('')
                setSheba('')
                resetForm()
                dispatch(getAccounts())
                dispatch(getAccounts())
                values.sheba = ''
                values.card_number = ''
                forceUpdate()
                setLoading(false)
            }
        } catch (ex) {
            setLoading(false)
            console.log(ex);
            if(ex.data.errors.card_number){
                toast.error(`${ex.data.errors.card_number[0]}`, {position: 'bottom-right', closeOnClick: true})
            }
            if(ex.data.errors.sheba){
                toast.error(`${ex.data.errors.sheba[0]}`, {position: 'bottom-right', closeOnClick: true})
            }
            setLoading(false)
        }
    }

    return (

        <section className='addDebitCard'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, {resetForm}) => {
                    handleAddCardDebit(values, resetForm)
                }}
            >
                {({values, setFieldValue, handleChange, handleSubmit, errors, setFieldTouched, touched, resetForm}) => (
                    <>
                        <div className="divLinkAddDebit">
                            <p className='pAddDebit'
                               onClick={(values, resetForm) => handleSubmit(values, resetForm)}>
                                {loading === false ? 'افزودن حساب جدید' :
                                    <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                             variant="secondary"/>
                                }
                            </p>
                        </div>

                        <form className='containerIrNumberCard'>

                            <div className="inputAddDebit">
                                <CustomInput inputType='text' number={true} value={values.sheba} textAlignLeft={true}
                                             placeholder='شماره شبا بدون IR' handleChange={handleChange('sheba')}
                                             handleBlur={() => setFieldTouched('sheba')} marginBottom='18px'/>
                                <ErrorMessage error={errors.sheba} visible={touched.sheba}/>
                            </div>

                            <div className='inputAddDebit'>
                                <InputCardNumber caption={true} placeholder='شماره کارت' onChange={handleChange('card_number')}
                                                 defaultlValue={values.card_number}
                                             onBlur={() => setFieldTouched('card_number')} marginBottom='18px'/>
                                <ErrorMessage error={errors.card_number} visible={touched.card_number}/>
                            </div>
                        </form>
                    </>
                )}
            </Formik>
        </section>
    );
}

export default AddDebitCard;