import React, {useState} from 'react';
import {DialogOverlay, DialogContent} from '@reach/dialog';
import parse from 'html-react-parser'
import imgCopy from './../../assets/img/copy.svg'
import ButtonForm from './../../components/ButtomForm/ButtomForm';
import './googleAuthen.scss'
import {Spinner} from "reactstrap";
import {verifyGoogle2af} from "../../services/google2afService";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {setUserInfo} from "../../redux/actions/userActions";
import {setDigit} from "../../redux/actions/digitActions";
import handleErrors from "../../utils/handleErrors";


const GoogleAuthen = ({showDialog, closeDialog, secretKet, qrImage}) => {

    const [isDisable, setIsDisable] = useState(false)
    const dispatch = useDispatch()
    const [numberrr, setNumberrr] = useState(['', '', '', '', '', ''])
    let codeNumber = ''
    const numOfFields = 6;
    const copyTextToClipboard = (text) => {
        if ('clipboard' in navigator) {
            toast.success('کد مورد نظر کپی شد.', {position: 'top-right', closeOnClick: true})
            return navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }
    const handle = async () => {
        const code = numberrr.join('')
        if (code.length > 5) {
            setIsDisable(true)
            const obj = {
                code: code
            }
            try {
                const {data} = await verifyGoogle2af(obj)
                if (data.type === 'success') {
                    setIsDisable(false)
                    dispatch(setUserInfo())
                    toast(`${data.message}`)
                    closeDialog()
                }
                setIsDisable(false)
            } catch (ex) {
                setIsDisable(false)
                handleErrors(ex)
                // toast.error(`${ex.data.message}`, {position: 'bottom-right', closeOnClick: true})
            }
        } else {
            toast.error('لطفا کد را کامل وارد کنید.')
        }

        // closeDialog()
    }
    const handleTextChange = (e, index) => {
        const {maxLength, value, name} = e.target;
        const [fieldName, fieldIndex] = name.split("-");
        let temp = [...numberrr]
        temp[index] = value;
        setNumberrr(temp)
        codeNumber = temp
        dispatch(setDigit(numberrr.join().replaceAll(',', '')))
        if (value.length >= maxLength) {
            if (parseInt(fieldIndex, 10) < numOfFields) {
                const nextSibling = document.querySelector(
                    `input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`
                );
                if (nextSibling !== null) {
                    nextSibling.focus();
                    return
                }
            }
        }
        if (value === '') {
            const previousSibling = document.querySelector(
                `input[name=ssn-${parseInt(index)}]`
            );
            if (previousSibling !== null) {
                previousSibling.focus();
                return
            }
        }

    }

    return (
        <DialogOverlay isOpen={showDialog} onDismiss={closeDialog} style={{background: "hsla(0,100%,100%,0.9)"}}>
            <DialogContent className='containerNewQuestion bgchangeDialog'>
                <div className='insideChangeDialog'>
                    <p className='titleGoogleModal'>لطفا بارکد زیر را توسط برنامه Google Authenticator گوشی خود اسکن
                        نمایید.</p>
                    <div className="w-100 text-center">
                        {parse(qrImage)}
                    </div>
                    <div className='sectionSecretCode'>
                        <div className='insideItemInvite '>
                            <p>{secretKet}</p>
                            <div>
                                <p>کد امنیتی :</p>
                                <img alt='' src={imgCopy} onClick={() => copyTextToClipboard(secretKet)}
                                     className='imgCopyInvite'/>
                            </div>
                        </div>
                    </div>
                    <div className='inputDigit6'>
                        <div className='containerInputCodeOPT'>
                            {numberrr.map((_, index) =>
                                <input key={index} maxLength={1}
                                       onChange={(e) => handleTextChange(e, index)}
                                       name={`ssn-${index + 1}`} className='inputCodeOPt' type="number"/>
                            )
                            }
                        </div>
                    </div>
                    <ButtonForm isDis={isDisable} funcClick={() => handle()} marginBottom='16px'>
                        {isDisable === false ? 'ثبت' :
                            <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                     variant="secondary"/>
                        }
                    </ButtonForm>
                </div>
            </DialogContent>
        </DialogOverlay>
    );
}

export default GoogleAuthen;