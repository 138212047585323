import React, {useState} from 'react'
import {Spinner} from "reactstrap";
import ButtonForm from "../ButtomForm/ButtomForm";
import CustomInput from "../CustomInput/CustomInput";
import {toast} from "react-toastify";
import {tx_id} from "../../services/transactionService";
import {Formik} from 'formik'
import * as Yup from 'yup'
import ErrorMessage from "../ErrorMessage/ErrorMessage";

const validationSchema = Yup.object().shape({
    tx_id: Yup.string().required('پر کردن این فیلد الزامی می باشد.')
})
const GetDepositID = ({closeDialog, id, reloadWallets}) => {

    const [isDisable, setIsDisable] = useState(false)

    const handle = async (values) => {
        setIsDisable(true)
        try {
            const {data} = await tx_id(id, values)
            if (data.type === 'success') {
                toast.success(`${data.message}`, {position: 'top-right', closeOnClick: true})
                setIsDisable(false)
                reloadWallets()
                closeDialog()
            }
        } catch (ex) {
            console.log(ex)
            toast.error(`${ex.data.message}`, {position: 'bottom-right', closeOnClick: true})
            setIsDisable(false)

        }
    }
    return (
        <div className='getDepositID'>
            <Formik initialValues={{tx_id: ''}} onSubmit={(values) => handle(values)}
                    validationSchema={validationSchema}>
                {({handleChange, handleSubmit, values, setFieldTouched, touched, errors}) => (
                    <>
                        <p className="pInWalletDeposit">
                            لطفا شناسه تراکنش را وارد کنید
                        </p>
                        <CustomInput inputType='text' handleChange={handleChange('tx_id')}
                                     handleBlur={() => setFieldTouched('tx_id')} placeholder='شناسه تراکنش'
                                     textAlignLeft={true} marginBottom='16px'/>
                        <ErrorMessage error={errors.tx_id} visible={touched.tx_id}/>
                        <ButtonForm isDis={isDisable} funcClick={handleSubmit}>
                            {isDisable === false ? 'ثبت' :
                                <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                         variant="secondary"/>
                            }
                        </ButtonForm>
                    </>
                )}
            </Formik>

        </div>
    )
}
export default GetDepositID