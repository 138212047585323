import React from 'react';
import './postItem.scss'
import {Link} from "react-router-dom";
import img from './image-1.png'

const PostItem = ({post}) => {
    return (
        <Link to={{pathname: post.link}} className='linkPost' target="_blank">
            <div className='postItem'>
                <div className='insidePostItem'>
                    <div className='containerImage'>
                        {/*<img alt='' src={post._embedded["wp:featuredmedia"][0]?.media_details?.sizes?.medium?.source_url:null}*/}
                        {/*<img alt='' src={post._embedded["wp:featuredmedia"]?[0]?.media_details?.sizes?.medium?.source_url:null}*/}
                        {/*     className='postImage'/>*/}
                        {post._embedded["wp:featuredmedia"] ? <img alt=''
                                                                   src={post._embedded["wp:featuredmedia"][0]?.media_details?.sizes?.medium?.source_url}
                                                                   className='postImage'/> : <img alt='' src={img}
                                                                                                  className='postImage'/>}
                    </div>
                    <p className='justify w-100 pInPostCard'>{post.title.rendered}</p>
                </div>
            </div>
        </Link>
    );
}

export default PostItem;