import React from 'react';

import FirstSell from '../FirstSell/FirstSell';

import './SellModeModal.scss'

const SellModeModal = ({ closeFunction }) => {

    const handleSell = () => {
        closeFunction()
    }

    return (
        <>
            <FirstSell closeFunction={closeFunction} />
        </>
    );
}

export default SellModeModal;