import React, {useRef, useState, useEffect} from 'react';
import Carousel from 'react-elastic-carousel'
import PostItem from '../PostItem/PostItem';

import imgLeft from './../../assets/img/slider/leftArrow.svg'
import imgRight from './../../assets/img/slider/rightArrow.svg'

import './post.scss'
import {getPosts} from "../../services/pagesService";
import Loading from "../Loading/Loading";

const Post = () => {

    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(true)
    const refCarousel = useRef()
    const breakPoints = [
        {width: 1, itemsToShow: 1},
        {width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false},
        {width: 850, itemsToShow: 3},
        // { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
    ]
    const initData = async () => {
        setLoading(true)
        try {
            // const {data} = await getPosts()
            const res = await getPosts()
            console.log(res)
            // console.log(data)
            setPosts(res.data)
            setLoading(false)
        } catch (ex) {
            console.log(ex)
            setPosts([])
            setLoading(false)
        }
    }

    useEffect(() => {
        initData()
    }, [])
    return (
        <section className='post'>
            {loading ? <Loading/> :
                <div className="insidePost">
                    <Carousel isRTL
                              ref={refCarousel}
                              showArrows={false}
                              pagination={false}
                              breakPoints={breakPoints}
                              itemPadding={[20, 5]}>
                        {posts.map(post => (
                            <PostItem post={post}/>
                        ))}
                    </Carousel>
                    <img alt='' onClick={() => refCarousel.current.slidePrev()} src={imgLeft} className='arrowLeft'/>
                    <img alt='' onClick={() => refCarousel.current.slideNext()} src={imgRight} className='arrowRight'/>
                </div>
            }
        </section>
    );
}

export default Post;