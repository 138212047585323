import React, {useState, useEffect} from 'react';
import Chart from "react-apexcharts";
import './chartTest.scss'
import {reverseArray} from "../../utils/Price";

const ChartTest = ({objData}) => {

    const [arr, setArr] = useState([...Object.values(objData ? objData : [])])
    const [options, setOptions] = useState({
        tooltip: {
            enalble: false,
            x: {
                show: false
            }
            ,
            y: {
                formatter: undefined,

            }
            ,
        }
        ,
        chart: {
            id: "basic-bar",
            toolbar: {
                show: false
            }
            ,
            height: "70",

        }
        ,

        legend: {
            show: false
        }
        ,
        stroke: {
            show: true,
            // curve: 'smooth',
            lineCap: 'butt',
            colors: ['#275BD1'],
            width: 1.5,
            dashArray: 0,
        }
        ,
        xaxis: {
        // axis: {
            labels: {
                show: false
            }
            ,
            tooltip: {
                enabled: false
            }
            ,
            show: false,
            lines: {
                show: false
            }
            // ,
            // categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
            // categories: [arr]
            ,
            axisBorder: {
                show: false
            }
            ,
            axisTicks: {
                show: false
            }
            ,

        }
        ,
        yaxis: {
            labels: {
                show: false
            }
            ,
            tooltip: {
                enabled: false
            }
            ,
            show: false
        }
        ,
        grid: {
            padding: {
                top: 0, right: 0, bottom: 0, left: 0
            }
            ,
            show: false
        }
    })

    const [series, setSeries] = useState([
        {
            name: "series-1",
            // data: [100, 10, 85, 20, 49, 60, 10, 91]
            data: [...reverseArray(arr)]
            // data: [...arr]
        }
    ],)

    return <div className="app">
        <div className='containerChart'>
            <div className="mixed-chart">
                <Chart
                    options={options}
                    series={series}
                    type="line"
                    width="100"
                    height="70"
                />
            </div>
        </div>
    </div>;
}

export default ChartTest;

