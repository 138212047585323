import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import CoinBase from './CoinBase';


const App = () => {
    return (
        <BrowserRouter>
            <CoinBase />
            <ToastContainer position='top-right' closeOnClick={true}/>
        </BrowserRouter>
    );
}

export default App;