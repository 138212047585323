import React, {useRef, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {buyMode, sellMode} from './../../redux/actions/sellBuyAcions';
import {floorNumber, numberWithCommas} from './../../utils/Price';
import Payment from '../Payment/Payment';
import ButtonForm from './../ButtomForm/ButtomForm';
import ContainerTables from './../ContainerTables/ContainerTables';
import iranImaeg from './../../assets/img/iran.svg'
import {withRouter} from 'react-router-dom'

// images
import lineDash from './../../assets/img/lineStyleDash.svg'
import Recive from '../Recive/Recive';
import convert from './../../assets/img/Convert.svg';

import './sellBuy.scss'
import arrowImaeg from "../../assets/img/ArrowRecive.svg";
import CryptoMenu from "../CryptoMenu/CryptoMenu";
import {initMarketCoins, setCoin} from "../../redux/actions/marketCoinsActions";
import {setMarketId} from "../../redux/actions/marketIdAction";
import {setIdCoin} from "../../redux/actions/selectCoinId";
import {toFixedDigit} from "../../utils/helperFunction";
import {toast} from "react-toastify";
import {Spinner} from "reactstrap";
import {orderBuy} from "../../services/ordersService";
import {toLocal} from "../../utils/convert";
import handleErrors from "../../utils/handleErrors";

const SellBuy = ({history}) => {

    const buy = useSelector(state => state.buy)
    const user = useSelector(state => state.user)
    const {rank_correction_factor} = user
    const dispatch = useDispatch()
    const refBar = useRef()
    const marketCoins = useSelector(state => state.marketCoins)
    const coin = useSelector(state => state.coin)
    const marketId = useSelector(state => state.marketId)
    const realData = useSelector(state => state.readData)
    const login = useSelector(state => state.login)
    // const coin = coins[coinId]
    const [amount, setAmount] = useState('')
    const [totalPrice, setTotalPrice] = useState(0)

    const [showCryptoMenu, setShowCryptoMenu] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [priceBuy, setPriceBuy] = useState(0)
    const [typeCal, setTypeCal] = useState('amount')
    const handleFocus = (event) => event.target.select();
    const handleChangeAmount = (val) => {
        // if(val ==='00'){
        //     setAmount(0)
        // }else{

        setAmount(val)
        // }
    }

    const handleConvert = () => {
        if (buy) {
            dispatch(sellMode())
        } else {
            dispatch(buyMode())
        }
    }
    const handleSubmit = async () => {
        if (login) {
            if (amount === 0) {
                toast.error('لطفا مقداری  وارد کنید', {position: 'bottom-right', closeOnClick: true})
            } else {
                setIsLoading(true)
                const obj = {
                    market_id: marketId,
                    type: (buy ? 'BUY' : 'SELL'),
                    amount
                }
                console.log(obj)
                try {
                    const {data} = await orderBuy(obj)
                    if (data.type === 'success') {
                        toast.success(`${data.message}`, {position: 'top-right', closeOnClick: true})
                        setIsLoading(false)
                    }
                } catch (ex) {
                    setIsLoading(false)
                    handleErrors(ex)
                    // toast.error(`${ex.data.message}`, {position: 'bottom-right', closeOnClick: true})
                    // if (ex.data.errors) {
                    //     toast.error(`${ex.data.errors.amount[0]}`, {position: 'bottom-right', closeOnClick: true})
                    // } else {
                    //     toast.error(`${ex.data.message}`, {position: 'bottom-right', closeOnClick: true})
                    // }
                }
            }
        } else {
            toast.warning('برای انجام معامله ابتدا باید وارد سایت شوید.', {position: 'top-right', closeOnClick: true})
            history.push('/login')
        }
    }

    const handleShowCryptoMenu = () => {
        setShowCryptoMenu(!showCryptoMenu)
    }
    const getRealPriceBuy = () => {
        if (coin.name) {
            const res = realData.find(rd => rd.coin === coin.coin)
            // if (!login) {
                return (res?.tomanBuying)
            // } else {
            //     const zarib = rank_correction_factor[coin.coin]
            //     return (res?.tomanBuying * zarib[0])
            // }
            // return res ? res.tomanBuying : 0
        } else {
            return 0
        }

    }

    const getRealPriceSelling = () => {
        if (coin.name) {
            const res = realData.find(rd => rd.coin === coin.coin)
            // if (!login) {
                return (res?.tomanSelling)
            // } else {
            //     const zarib = rank_correction_factor[coin.coin]
            //     return (res?.tomanSelling * zarib[1])
            // }
        } else {
            return 0
        }

    }

    const initial = () => {
        dispatch(setMarketId(1))
        dispatch(setIdCoin(1))
        dispatch(setCoin(1))
    }

    function validate(e) {
        let theEvent = e || window.event;
        let key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        let regex = /[0-9]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    const getRealPrice = () => {
        // const {tomanBuying} = realData.find(rd => rd.coin === coin.coin)
        const obj = realData.find(rd => rd.coin === coin.coin)
        return obj?.tomanBuying
        // if (!login) {
        //     return (tomanBuying)
        // } else {
        //     const zarib = rank_correction_factor[coin.coin]
        //     return (tomanBuying * zarib[0])
        // }
        // return tomanBuying
    }
    const valuePay = () => {
        var result = ''
        if (typeCal === 'amount') {
            if (buy) {
                // result = numberWithCommas(amount * toFixedDigit(getRealPriceBuy()))
                // result = amount * toFixedDigit(getRealPriceBuy())
                result = amount * getRealPriceBuy()
            } else {
                // result = numberWithCommas(amount * toFixedDigit(getRealPriceSelling()))
                // result = amount * toFixedDigit(getRealPriceSelling())
                result = amount * getRealPriceSelling()
            }
        } else {
            // result = numberWithCommas(totalPrice)
            result = totalPrice
        }
        // console.log(result);
        // result = Number(result)
        return toLocal(result)
    }
    useEffect(() => {
        setPriceBuy(amount * toFixedDigit(getRealPrice()))
        // console.log(priceBuy)
    }, [amount, coin.buyingPrice])
    useEffect(() => {
        const result = amount * toFixedDigit(getRealPrice())
        setPriceBuy(result)
    }, [amount])
    useEffect(() => {
        let result = totalPrice / toFixedDigit(getRealPrice())
        setAmount(result)
        setPriceBuy(amount * toFixedDigit(getRealPrice()))
    }, [totalPrice])

    useEffect(() => {
        initial()
    }, [])

    return (
        <div className='sellBuy'>
            <div className='insideSellBuy'>
                <div className='boxBtnSellBuy row'>
                    <div className='col-6 boxBtn' onClick={() => dispatch(sellMode())}>
                        <p style={{color: buy ? '#828282' : ' #4F4F4F'}} className='pBtnBuySell'>فروش به ما</p>
                    </div>
                    <div className='col-6 boxBtn' onClick={() => dispatch(buyMode())}>
                        <p style={{color: buy ? '#4F4F4F' : ' #828282'}} className='pBtnBuySell'>خرید از ما</p>
                    </div>
                    <div ref={refBar} className={`barBelowBtn ${!buy ? 'moveLeft' : ''}`}></div>
                </div>
                <div className='containerAniation'>

                    {/*<Payment />*/}
                    <div className={!buy ? 'paymentSection goDown' : 'paymentSection'}>

                        <div className='payment'>
                            <div className='insidePayment'>
                                <input
                                    pattern='[0-9]*'
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                                    onKeyPress={(e) => validate(e)}
                                    onChange={(e) => {
                                        setTypeCal('toman')
                                        var str = e.target.value
                                        setTotalPrice(str.replaceAll(',', ''))
                                    }}
                                    value={valuePay() == 0 ? '':valuePay()}
                                    // value={toLocal(valuePay())}
                                    placeholder='مقدار پول' className='inputInPayment'/>
                                <div className='rightPayment'>
                                    <span className='spanTomanPayment reg14'>تومان</span>
                                    <img alt='' src={iranImaeg} className='imgInPayment'/>
                                </div>
                            </div>
                        </div>
                        <p className='pBelowPayment'>{buy ? 'پرداخت میکنید' : 'دریافت میکنید'}</p>
                    </div>

                    {/* end payment */}
                    {/* <div className='text-center'> */}
                    <img src={convert} onClick={() => handleConvert()} alt='' className='imgConvertSellBuy'/>
                    {/* </div> */}
                    {/*<Recive/>*/}
                    <div className={!buy ? 'reciveSection goUp' : 'reciveSection'}>

                        <div className='recive'>
                            <div className='insideRecive'>
                                <input
                                    onFocus={handleFocus}
                                    pattern='[0-9]*'
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                                    onKeyPress={(e) => validate(e)}
                                    placeholder={`مقدار ${coin.name}`}
                                    onChange={
                                        (e) => {
                                            setTypeCal('amount')
                                            var str = e.target.value
                                            handleChangeAmount(str.replaceAll(',', ''))
                                        }
                                    }
                                    value={typeCal === 'amount' ? (toLocal(amount)) : toLocal(totalPrice / getRealPrice())}
                                    className='inputInRecive'/>
                                <div className='rightRecive' onClick={handleShowCryptoMenu}>
                                    <img alt='' src={arrowImaeg} className='arrowImaegRecive'/>
                                    <span className='spanNameRecive reg14'>{coin.name}</span>
                                    <img alt='' src={coin.img} className='imgCoinRecive'/>
                                </div>
                            </div>
                            {showCryptoMenu ?
                                <CryptoMenu listItems={marketCoins} showMeState={showCryptoMenu}
                                            closeMe={setShowCryptoMenu}/> : null}
                        </div>
                        <p className='pBelowRecive'>{buy ? 'دریافت میکنید' : 'پرداخت میکنید'}</p>
                    </div>

                    {/* End Recive */}
                </div>

                <img alt='' src={lineDash} className='imgDash'/>
                <div className='resultSellBuy'>
                    <p className='reg14 presultSellBut'>{`تومان ${coin ? (buy ? numberWithCommas(toFixedDigit(getRealPriceBuy())) : numberWithCommas(toFixedDigit(getRealPriceSelling()))) : ''}`}</p>
                    <p className='reg14 presultSellBut'>1 {coin.name}</p>
                </div>
                <ButtonForm funcClick={() => handleSubmit()} marginBottom='16px'>
                    {isLoading === false ?
                        (buy ? 'خرید رمز ارز' : 'فروش رمز ارز')
                        :
                        <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                 variant="secondary"/>
                    }
                </ButtonForm>
            </div>
        </div>
    );
}

export default withRouter(SellBuy);
