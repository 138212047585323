import React, {useEffect, useState} from 'react';
import FAQItem from './../../components/FAQItem/FAQItem';
import {getFaqs} from "../../services/faqsService";
import Loading from "../../components/Loading/Loading";
import './faq.scss'

const FAQ = () => {

    const [loading, setLoading] = useState(false)
    const [faqs, setFaqs] = useState([])

    const getFaqFromBackend = async () => {
        setLoading(true)
        try {
            const {data} = await getFaqs()
            if (data.type === 'success') {
                setFaqs(data.data)
                setLoading(false)
            }
        } catch (ex) {
            setLoading(false)
            console.log(ex)
        }
    }

    useEffect(() => {
        document.title = 'ویستا  || سوالات متداول'
        getFaqFromBackend()
    }, [])

    return (
        <section className='faq'>
            <div className="insideFAQ">
                <p className='titleInFAQ'>سوالات متداول</p>
                {loading ? <Loading/> :
                    faqs.length < 1 ?
                        <p className='pTopAboutUs'>هنوز سوال و پاسخی درج نشده است.</p> :
                        faqs.map((faq,index) => (
                            <FAQItem key={index} question={faq.question} answer={faq.answer}/> ))
                }
            </div>
        </section>
    );
}

export default FAQ;