import React, {useState, useEffect} from "react";
import ComponentMenu from "./../../components/ComponentMenu/ComponentMenu";
import {DialogContent, DialogOverlay} from "@reach/dialog";
import warningImage from "./../../assets/img/warning.svg";
import copyAddress from "./../../assets/img/copy.svg";
import "./walletDepoditModal.scss";
import {useDispatch, useSelector} from "react-redux";
import ButtonForm from "../../components/ButtomForm/ButtomForm";
import {depositCrypto, getDepositAddress} from "../../services/depositWithdrawService";
import {setAddressQR} from "../../redux/actions/addressAction";
import parse from "html-react-parser";
import {toast} from "react-toastify";
import Loading from "../../components/Loading/Loading";
import {Spinner} from "reactstrap";
import GetDepositID from "../../components/GetDepositID/GetDepositID";
import handleErrors from "../../utils/handleErrors";


const WalletDepoditModal = ({
                                showDialog,
                                closeDialog,
                                net = -1,
                                coinId,
                                label,reloadWallets
                            }) => {

    const [network, setNetwork] = useState(net);
    const [showQR, setShowQr] = useState(false)
    const [first, setFirst] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isDisable, setIsDisable] = useState(false)
    const [stepGetId, setStepGetId] = useState(false)
    const [id, setId] = useState(0)

    const listNetwork = useSelector((state) => state.listNetwork);
    const addressQR = useSelector((state) => state.address);
    const dispatch = useDispatch()

    const copyTextToClipboard = (text) => {
        if ("clipboard" in navigator) {
            toast.success('آدرس مورد نظر کپی شد.', {position: 'top-right', closeOnClick: true})
            return navigator.clipboard.writeText(text);
        } else {
            return document.execCommand("copy", true, text);
        }
    };

    const getAddress = async () => {
        setShowQr(false)
        setLoading(true)
        try {
            const {data} = await getDepositAddress(listNetwork[network].value)
            if (data.type === 'success') {
                setShowQr(true)
                dispatch(setAddressQR(data.data))
                setLoading(false)
            }
        } catch (ex) {
            setLoading(false)
            console.log(ex);
        }
    }
    const handleSubmit = async () => {
        const obj = {
            payment_method: "CRYPTO",
            coin_id: coinId,
            crypto_network_id: listNetwork[network].value
        }
        try {
            setIsDisable(true)
            const {data} = await depositCrypto(obj)
            if (data.type === 'success') {
                toast.success(`${data.message}`)
                setId(data.data.id)
                setIsDisable(false)
                setStepGetId(true)
            }
        } catch (ex) {
            handleErrors(ex)
            // toast.error(`${ex.data.message}`)
            setIsDisable(false)
        }
    }
    useEffect(() => {
        if (first) {
            if (network > -1) {
                getAddress()
            }
        } else {
            setFirst(true)
        }
    }, [network]);
    useEffect(() => {
        return () => {
            setNetwork(net)
            setShowQr(false)
        }

    }, [])
    useEffect(() => {
        setShowQr(false)
        setNetwork(-1)

        setStepGetId(false)
    }, [showDialog])
    return (
        <DialogOverlay
            isOpen={showDialog}
            onDismiss={closeDialog}
            style={{background: "var(--bg-modal)"}}
        >
            <DialogContent className="walletDepoditModal">
                <div className="insideModal">
                    <p className="titleInWalletDeposit">{`واریز ${label}`}</p>
                    {!stepGetId?<ComponentMenu
                        marginBottom="16px"
                        setID={setNetwork}
                        list={listNetwork}
                        placeholder={
                            network < 0 ? "انتخاب شبکه" : listNetwork[network].title
                        }
                    />:null}
                    {loading ? <Loading/> : null}
                    {showQR && !stepGetId ?
                        <>
                            <p className="pInWalletDeposit">
                                آدرس کیف پول شما در زیر قابل مشاهده است. برای واریز {label} به این کیف پول، می توانید از
                                این
                                آدرس استفاده کنید.
                            </p>
                            <div className="divQR">
                                <div className="row text-center">
                                    {/*<img src={imgQR} className="img-QR" alt=""/>*/}
                                    <div className="imgQR">
                                        {addressQR.address_image ? parse(addressQR.address_image) : null}
                                    </div>
                                </div>
                                <div className="divCopy">
                                    <p className="pCopy">
                                        {" "}
                                        کپی آدرس
                                        <img
                                            alt=''
                                            src={copyAddress}
                                            className="copyAddress"
                                            onClick={() => {
                                                copyTextToClipboard(addressQR.address)
                                            }}
                                        />
                                    </p>
                                    <p className="pCopy2">آدرس کیف پول {label}</p>
                                </div>
                                <p className="address-deposite">{addressQR.address}</p>
                            </div>
                            {/* start memo*/}
                            {addressQR.memo?
                                <div className="divQR">
                                    <div className="row text-center">
                                        {/*<img src={imgQR} className="img-QR" alt=""/>*/}
                                        <div className="imgQR">
                                            {addressQR.memo_image ? parse(addressQR.memo_image) : null}
                                        </div>
                                    </div>
                                    <div className="divCopy">
                                        <p className="pCopy">
                                            {" "}
                                            کپی آدرس
                                            <img
                                                alt=''
                                                src={copyAddress}
                                                className="copyAddress"
                                                onClick={() => {
                                                    copyTextToClipboard(addressQR.memo)
                                                }}
                                            />
                                        </p>
                                        <p className="pCopy2">آدرس کیف پول {label}</p>
                                    </div>
                                    <p className="address-deposite">{addressQR.memo}</p>
                                </div>
                                :null}
                            {/* end memo*/}
                            <div className="divWarning">
                                <p>
                                    این کیف پول فقط برای واریز {label} می باشد. واریز هر ارز دیگری به این آدرس موجب از
                                    دست رفتن
                                    دارای شما می شود.
                                </p>
                                <img src={warningImage} alt=""/>
                            </div>
                            <ButtonForm isDis={isDisable} funcClick={() => handleSubmit()}>
                                {isDisable === false ? 'ثبت' :
                                    <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                             variant="secondary"/>
                                }
                            </ButtonForm>

                        </>
                        : null}
                    {stepGetId?<GetDepositID reloadWallets={()=>reloadWallets()} id={id} closeDialog={closeDialog}/>:null}

                </div>
            </DialogContent>
        </DialogOverlay>
    );
};

export default WalletDepoditModal;
