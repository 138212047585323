import React, {useRef, useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {numberWithCommas} from '../../utils/Price';


// images
import arrow from './../../assets/img/ArrowRecive.svg'
import dashLine from './../../assets/img/lineStyleDash.svg'

import './step1Buy.scss'
import CryptoMenu from '../CryptoMenu/CryptoMenu';
import ButtonForm from "../ButtomForm/ButtomForm";
import {Spinner} from "reactstrap";
import {toast} from "react-toastify";
import {orderBuy} from "../../services/ordersService";
import {toFixedDigit} from "../../utils/helperFunction";
import {toLocal} from "../../utils/convert";
import handleErrors from "../../utils/handleErrors";

const Step1Buy = ({closeFunction}) => {

    const [showCryptoMenu, setShowCryptoMenu] = useState(false)
    const [amount, setAmount] = useState('')
    const [totalPrice, setTotalPrice] = useState(0)
    const [priceBuy, setPriceBuy] = useState(0)
    const [isDisable, setIsDisable] = useState(false)
    const [credit, setCredit] = useState({})
    const [typeCal, setTypeCal] = useState('amount')

    const refCrypto = useRef()

    const marketCoins = useSelector(state => state.marketCoins)
    const coin = useSelector(state => state.coin)
    const marketId = useSelector(state => state.marketId)
    const realData = useSelector(state => state.readData)
    const login = useSelector(state => state.login)
    const user = useSelector(state => state.user)
    const {rank_correction_factor} = user
    const {credits} = user

    const [mojodi, setMojodi] = useState('')

    const handleGetCredit = async () => {
        const cre = await user.credits.find(item => item.label === coin.name)
        setMojodi(cre ? cre.credit : '0')
        setCredit(cre)

    }
    const handleChangeAmount = (val) => {
        setAmount(val.replaceAll(',', ''))
    }

    function validate(e) {
        let theEvent = e || window.event;
        let key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        let regex = /[0-9]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    const handleOrderBuy = async () => {
        if (amount > 0) {
            setIsDisable(true)
            const obj = {
                market_id: marketId,
                type: 'BUY',
                amount
            }
            try {
                const {data} = await orderBuy(obj)
                if (data.type === 'success') {
                    toast.success(`${data.message}`)
                    setIsDisable(false)
                    closeFunction()
                }
            } catch (ex) {
                setIsDisable(false)
                console.log(ex)
                handleErrors(ex)
                // if (ex.data.errors) {
                //     toast.error(`${ex.data.errors.amount[0]}`, {position: 'bottom-right', closeOnClick: true})
                // } else {
                //     toast.error(`${ex.data.message}`, {position: 'bottom-right', closeOnClick: true})
                // }
                // toast.error(`${ex.data.message}`, {position: 'bottom-right', closeOnClick: true})
            }

        } else {
            toast.error('لطفا مقدار را وارد کنید.')
        }
    }

    const getRealPrice = () => {
        const {tomanBuying} = realData.find(rd => rd.coin === coin.coin)
        if (!login) {
            return (tomanBuying)
        } else {
            const zarib = rank_correction_factor[coin.coin]
            return (tomanBuying * zarib[0])
        }
        // return tomanBuying
    }

    useEffect(() => {
        setPriceBuy(amount * toFixedDigit(getRealPrice()))
        // console.log(priceBuy)
    }, [amount, coin.buyingPrice])
    useEffect(() => {
        const result = amount * toFixedDigit(getRealPrice())
        setPriceBuy(result)
    }, [amount])
    useEffect(() => {
        let result = totalPrice / toFixedDigit(getRealPrice())
        setAmount(result)
        setPriceBuy(amount * toFixedDigit(getRealPrice()))
    }, [totalPrice])

    useEffect(() => {
        handleGetCredit()
        // console.log(coin)
    }, [marketId])
    useEffect(() => {
        // dispatch(setCoin(1))
        handleGetCredit()
        const pusher = require('pusher-js');


    }, [])

    function validate(e) {
        let theEvent = e || window.event;
        let key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        let regex = /[0-9]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    const handleFocus = (event) => event.target.select();
    return (
        <div className='step1Buy' onKeyDown={e => {
            if (e.key === 'Enter') {
                handleOrderBuy()
            }
        }}>
            <div className='coinPrice'>
                <p className='pPriceSellMode colorGreen'>تومان <span
                    className='spanpPriceSellMode colorGreen'>{numberWithCommas(toFixedDigit(getRealPrice()))}</span>
                </p>
                <p className='countBit'>{`1 ${coin.name}`}</p>
            </div>

            <div className='boxImgCoinValue'>
                <span>مقدار</span>
                <div className='insideBoxImgCoinValue'>
                    <input
                        onFocus={handleFocus}
                        pattern='[0-9]*'
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                        onKeyPress={(e) => validate(e)}
                        placeholder={`مقدار ${coin.name}`}
                        onChange={
                            (e) => {
                                setTypeCal('amount')
                                handleChangeAmount(e.target.value)
                            }
                        }
                        value={typeCal === 'amount' ? (toLocal(amount)) : toLocal(totalPrice / getRealPrice())}
                    />
                    <div ref={refCrypto} onClick={() => setShowCryptoMenu(!showCryptoMenu)}>
                        <img src={arrow} alt=""/>
                        <p>{coin.name}</p>
                        <img className='imgCoinSelected' src={coin.img} alt=""/>
                    </div>
                </div>
                {showCryptoMenu ? <CryptoMenu showMeState={showCryptoMenu} closeMe={setShowCryptoMenu}
                                              listItems={marketCoins}/> : null}
            </div>
            {/* start OR */}
            <div className='containerOr'>
                <div></div>
                <p>یا</p>
            </div>
            {/* end OR */}

            <div className='totaPriceToman'>
                <span>مبلغ کل به تومان</span>
                <input
                    pattern='[0-9]*'
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    onKeyPress={(e) => validate(e)}
                    onChange={(e) => {
                        setTypeCal('toman')
                        var str = e.target.value
                        setTotalPrice(str.replaceAll(',', ''))
                    }}
                    value={typeCal === 'amount' ?
                        numberWithCommas(amount * toFixedDigit(getRealPrice())) == 0 ? '' : numberWithCommas(amount * toFixedDigit(getRealPrice())) :
                        numberWithCommas(totalPrice) == 0 ? '' : numberWithCommas(totalPrice)
                    }
                    placeholder='مبلغ کل به تومان'/>
            </div>
            {/* Mojodi */}
            <div className='mojodi'>
                <p>{`${mojodi}`}</p>
                <p>موجودی :</p>
            </div>
            {/* End Mojodi */}
            <img src={dashLine} alt=""/>
            {/* amount payable */}
            <div className='amountPayable'>
                <p>{`تومان ${numberWithCommas(amount * toFixedDigit(getRealPrice()))}`}</p>
                <p>مبلغ قابل پرداخت</p>
            </div>
            {/*end amount payable */}
            <ButtonForm funcClick={() => handleOrderBuy()}>
                {isDisable === false ? 'خرید رمز ارز' :
                    <Spinner size={'sm'} className='invoice-list-wrapper' animation="border" variant="secondary"/>
                }
            </ButtonForm>
        </div>
    );
}

export default Step1Buy;