import React, {useEffect} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom'
import MainLayout from './../layouts/mainLayout/MainLayout';
import ScrollToTop from './../utils/ScrollToTop';
import Home from './../pages/Home/Home';
import FAQ from './../pages/FAQ/FAQ';
import Login from './../pages/Login/Login';
import Register from './../pages/Register/Register';
import Echo from 'laravel-echo';

// import RegisterOPT from './../pages/RegisterOPT/RegisterOPT';
import ForgotPassword from './../pages/ForgotPassword/ForgotPassword';
import ForgotPasswordOPT from './../pages/ForgotPasswordOPT/ForgotPasswordOPT';
import SetNewPassword from './../pages/SetNewPassword/SetNewPassword';
import AboutUs from './../pages/AboutUs/AboutUs';
import HowToUse from './../pages/HowToUse/HowToUse';
import PrivacyPolicy from './../pages/PrivacyPolicy/PrivacyPolicy';
import Markets from './../pages/Markets/Markets';
import Account from './../pages/Account/Account';
import NotFound from './../pages/NotFound/NotFound';
import {useDispatch, useSelector} from "react-redux";
import {withRouter} from 'react-router-dom'
import {setReadData} from "../redux/actions/realDataAction";
import {setWeeklyPrice} from "../redux/actions/weeklyPriceAction";
import {initMarketCoins} from "../redux/actions/marketCoinsActions";
import ReturnPage from "../pages/ReturnPage/ReturnPage";
import {paginate} from "../utils/paginate";
import Rules from "../pages/Rules/Rules";

const CoinBase = (props) => {

    const login = useSelector(state => state.login)
    const dispatch = useDispatch()
    const marketCoins = useSelector(state => state.marketCoins)
    const indexCoins = paginate(marketCoins, 1, 10)
    useEffect(() => {

        const pusher = require('pusher-js');

        let echo = new Echo({
            // broadcaster: 'pusher',
            // key: 'local',
            // wsHost: 'dashboard.arztrading.com',
            // wssPort: 8443,
            // forceTLS: true,
            // cluster: 'mt1',
            // disableStats: true,
            // key: 'local',
            // authEndpoint: "http://185.110.189.39/broadcasting/auth",
            broadcaster: 'pusher',
            key: 'local',
            wsHost: 'coinrace.website',
            wsPort: 6003,
            wssPort: 6003,
            forceTLS: true,
            cluster: 'mt1',
            disableStats: true,
            enabledTransports: ['ws', 'wss'],
        });
        dispatch(setWeeklyPrice())
        dispatch(initMarketCoins())

        echo.channel(`realtime-price`)
            .listen('RealTimePriceEvent', (e) => {
                // console.log('socket',e);
                const {prices} = e
                dispatch(setReadData(prices))
            });

    }, [])


    return (
        <MainLayout>
            <ScrollToTop>
                <Switch>
                    <Route path='/faq' component={FAQ}/>
                    {/* <Route path='/login' component={Login}/> */}
                    <Route path='/login'>
                        {login ? <Redirect to='/'/> : <Login/>}
                    </Route>
                    <Route path='/forgotpassword' component={ForgotPassword}/>
                    <Route path='/forgotpasswordopt' component={ForgotPasswordOPT}/>
                    <Route path='/setnewpassword' component={SetNewPassword}/>
                    {/* <Route path='/register/:email?' component={Register} /> */}
                    <Route path='/register/:email?'>
                        {login ? <Redirect to='/'/> : <Register/>}
                    </Route>
                    <Route path='/privacypolicy' component={PrivacyPolicy}/>
                    <Route path='/howtouse' component={HowToUse}/>
                    <Route path='/faq' component={FAQ}/>
                    <Route path='/rules' component={Rules}/>
                    <Route path='/aboutus' component={AboutUs}/>
                    <Route path='/markets' component={Markets}/>
                    <Route path='/account' component={Account}/>
                    <Route path='/returnpage/:id?' component={ReturnPage}/>
                    {/*<Route path='/' exact component={Home}/>*/}
                    <Route path='/' exact render={() => <Home inMarketCoins={indexCoins}/>}/>
                    <Route path='*' component={NotFound}/>
                </Switch>
            </ScrollToTop>
        </MainLayout>
    );
}

export default withRouter(CoinBase);