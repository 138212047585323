import React, {useEffect, useState} from 'react';
import {numberWithCommas} from './../../utils/Price';
import {useSelector} from 'react-redux';

import CryptoMenu from '../CryptoMenu/CryptoMenu';

// images
import arrow from './../../assets/img/ArrowRecive.svg'
import dashLine from './../../assets/img/lineStyleDash.svg'
import ButtonForm from "../ButtomForm/ButtomForm";
import {Spinner} from "reactstrap";
import {toast} from "react-toastify";
import {orderBuy} from "../../services/ordersService";
import {toFixedDigit} from "../../utils/helperFunction";
import {toLocal} from "../../utils/convert";
import handleErrors from "../../utils/handleErrors";

const FirstSell = ({closeFunction}) => {

    const [showCryptoMenu, setShowCryptoMenu] = useState(false)

    const realData = useSelector(state => state.readData)


    const [amount, setAmount] = useState('')
    const [totalPrice, setTotalPrice] = useState('')
    const [priceBuy, setPriceBuy] = useState(0)
    const [isDisable, setIsDisable] = useState(false)
    const marketCoins = useSelector(state => state.marketCoins)
    const coin = useSelector(state => state.coin)
    const marketId = useSelector(state => state.marketId)


    const handleOrderSell = async () => {
        if (amount != 0) {
            setIsDisable(true)
            const obj = {
                market_id: marketId,
                type: 'SELL',
                amount
            }
            try {
                const {data} = await orderBuy(obj)
                console.log(data)
                if (data.type === 'success') {
                    toast.success(`${data.message}`)
                    setIsDisable(false)
                    closeFunction()
                }
            } catch (ex) {
                console.log(ex)
                setIsDisable(false)
                handleErrors(ex)
            }
        } else {
            toast.error('لطفا مقدار را وارد کنید.')
        }
    }

    const getRealPrice = () => {
        const {tomanSelling} = realData.find(rd => rd.coin === coin.coin)
        return (tomanSelling)
    }

    function validate(e) {
        let theEvent = e || window.event;
        let key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        let regex = /[0-9]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    useEffect(() => {
        const result = amount * getRealPrice()
        setPriceBuy(result)
        setTotalPrice(result)
    }, [amount])
    const handleFocus = (event) => event.target.select();
    return (
        <>
            <div className='coinPrice'>
                <p className='pPriceSellMode'>تومان <span
                    className='spanpPriceSellMode'>{numberWithCommas(toFixedDigit(getRealPrice()))}</span></p>
                <p className='countBit'>1 {coin.name}</p>
            </div>

            <div className='boxImgCoinValue' onKeyDown={e => {
                if (e.key === 'Enter') {
                    handleOrderSell()
                }
            }}>
                <div className='insideBoxImgCoinValue'>
                    <input className='inputTopBox'
                           type="text"
                           onKeyPress={(e) => validate(e)}
                           onFocus={handleFocus}
                           placeholder={`مقدار ${coin.name}`}
                           onChange={
                               (e) => {
                                   const str = e.target.value
                                   setAmount(str.replaceAll(',', ''))
                               }
                           }
                           value={toLocal(amount)}
                    />
                    <div onClick={() => setShowCryptoMenu(!showCryptoMenu)} className='rightTopBox'>
                        <img src={arrow} alt=""/>
                        <p>{coin.name}</p>
                        <img className='imgCoinSelected' src={coin.img} alt=""/>
                    </div>
                </div>
                {showCryptoMenu ? <CryptoMenu showMeState={showCryptoMenu} closeMe={setShowCryptoMenu}
                                              listItems={marketCoins}/> : null}
            </div>
            <img src={dashLine} className='lineInSellMode' alt=''/>
            <div className='totalPrice'>
                <p>{`تومان ${numberWithCommas(amount * toFixedDigit(getRealPrice()))}`}</p>
                <p>مبلغ کل:</p>
            </div>
            <ButtonForm funcClick={() => handleOrderSell()}>
                {isDisable === false ? 'فروش رمز ارز' :
                    <Spinner size={'sm'} className='invoice-list-wrapper' animation="border" variant="secondary"/>
                }
            </ButtonForm>
        </>
    );
}

export default FirstSell;