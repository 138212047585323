import React, {useEffect, useRef} from 'react';

// image
import dontImage from './../../assets/img/profile/dont.png'
import doImage from './../../assets/img/profile/do.png'
import selfiImage from './../../assets/img/profile/selfi.svg'

import './InsideAuthen.scss'
import {uploadImage, downloadImage} from './../../services/personService';
import {toast} from 'react-toastify';
import {useState} from 'react';
import Loading from '../Loading/Loading';
import _ from 'lodash'

import axios from 'axios';

const InsideAuthen = () => {

    const refUpload = useRef()
    const refImage = useRef()

    const [imgPre, setImagePre] = useState(false)
    const [isDisable, setIsDisable] = useState(false)
    const [preLoader, setPreLoader] = useState(false)
    const [src, setSRC] = useState()
    const [obj, setObj] = useState({})

    const handleCickUpload = () => {
        refUpload.current.click()
    }

    const handleGetImage = async () => {
        // setPreLoader(true)
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
            const {data} = await downloadImage()
            if (data.type === "success") {
                setObj(data.data[0]);
                if (data.data.length > 0) {
                    setImagePre(true)
                }
                setPreLoader(false)
                setIsDisable(false)
            }

        } catch (error) {
            console.log(error);
            setPreLoader(false)
        }
    }

    const formData = new FormData()

    const onFileChange = async e => {
        setIsDisable(true)
        if (e.target && e.target.files[0]) {
            formData.append("type", "SELFIE")
            formData.append('document', e.target.files[0])
            try {
                const {data} = await uploadImage(formData)
                if (data.type === 'success') {
                    setObj(data.data);
                    setSRC(e.target.files[0])
                    setIsDisable(false)
                    toast.success(`${data.message}`, {position: 'top-right', closeOnClick: true})
                    handleGetImage()
                }
            } catch (ex) {
                setIsDisable(false)
                console.log(ex);
            }
        }
    }

    useEffect(() => {
        handleGetImage()
    }, [])
    // useEffect(() => {
    //     console.log(obj)
    // }, [obj])

    return (
        <>
            <p className='mainPAuthen'>
                لطفا تصویر کارت ملی را در کنار چهره خودتان همراه با یک دست نوشته به صورت کاملا خوانا و واضح ،دقیقا مانند
                تصویر نمونه ،ارسال نمایید. دقت شود متن دست نوشته نبایستی تایپ شود. هم چنین توجه داشته باشید هنگام تهیه
                تصویر ، متن دست نوشته و اطلاعات کارت ملی به صورت برعکس و آینه ای ثبت نشود.
            </p>
            <p className='greenText '>متن دست نوشته :</p>
            <p className='greenText '>
                اینجانب (نام و نام خانوادگی) به کد ملی () ضمن مطالعه و تایید قوانین استفاده از خدمات بامبو، متعهد می
                گردم که حساب کاربری و مدارک خود را به منظور خرید و فروش ارزهای دیجیتال در اختیار سایر افراد قرار ندهم.
            </p>
            <p className='greenText '>جهت احراز هویت در سایت بامبو</p>
            <p className='greenText '>نام و نام خانوادگی امضا و تاریخ</p>
            <div className='containerImagesProfile'>
                <div className='insideContainerImagesProfile'>
                    <img src={dontImage} alt=""/>
                    <img src={doImage} alt=""/>
                    <div className='uploadImge'>
                        {isDisable ? <Loading/> :
                            <>
                                {/* {imgPre ? <img src={URL.createObjectURL(src)} ref={refImage} className='imaUpload' alt='' /> : <img alt='' className='beforImage' src={selfiImage} />} */}
                                {imgPre ? <img src={obj?.image} ref={refImage} className='imaUpload' alt=''/> :
                                    <img alt='' className='beforImage' src={selfiImage}/>
                                }
                                <button onClick={handleCickUpload}>آپلود عکس سلفی</button>
                                <input ref={refUpload} type="file" onChange={onFileChange}
                                       accept="image/png, image/jpeg"/>
                            </>}

                    </div>
                </div>

            </div>
        </>
    );
}

export default InsideAuthen;