const p2e= string => string.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))

export const toLocal = (number) => {
    //purify number
    number = number + ''
    if (number.includes('E')) {
        number = parseFloat(number) + ''
    }
    number = p2e(number).replace(/[^\d.]|\.\./ig, '').replace(/,/g, '')
    if (number.includes('.')) {
        number = number.split('.')[0] + '.' + (number.split('.')[1] || '')
    }

    if (parseFloat(number)) {

        if (parseFloat(number) < 1.0e-8) {
            //zero numbers
            return 0
        } else {
            //separate number
            const int = parseFloat(number.split('.')[0]).toLocaleString()
            if (number.includes('.')) {
                return int + '.' + (number.split('.')[1] || '')
            } else {
                return int
            }
        }

    } else {
        return number
    }

}

// that.$S2N = e => parseFloat(e.replace(/,/g, ''))