import React, { useEffect, useRef, useState } from 'react';
import arrow from './Vector.svg'
import './faqItem.scss'
import { setViewNotification } from '../../services/notificationsService';
import moment from 'jalali-moment'
import {convertDateTime} from "../../utils/Price";


const FAQItem = ({ answer, question, dateTime, isRead, id }) => {

    const refFAQ = useRef()
    const refPAnswer = useRef()
    const refPQuestion = useRef()
    const sectionQuetionRef = useRef()

    const [showAnswer, setShowAnswer] = useState(false)

    const handleShowAnswer = async () => {
        setShowAnswer(!showAnswer)
    }
    // close when click outside
    useEffect(() => {
        const checkOpen = (e) => {
            if (showAnswer && refFAQ.current && !refFAQ.current.contains(e.target)) {
                setShowAnswer(false)
            }
        }
        document.addEventListener('click', checkOpen)
        return () => document.removeEventListener('click', checkOpen)
    }, [showAnswer])

    useEffect(() => {
        const defaultHeight = (refPQuestion.current.getBoundingClientRect().height + 30)
        sectionQuetionRef.current.style.height = defaultHeight + 'px'
        const newHeight = (refPAnswer.current.getBoundingClientRect().height + 18 + defaultHeight) + 'px'
        if (showAnswer) {
            refFAQ.current.style.height = newHeight
            refPAnswer.current.style.opacity = '1'
        } else {
            refFAQ.current.style.height = defaultHeight + 'px'
            refPAnswer.current.style.opacity = '0'
        }
    }, [showAnswer])
    useEffect(()=>{
        if(isRead){
            sectionQuetionRef.current.classList.add('isReadFAQ')
        }else{
            sectionQuetionRef.current.classList.remove('isReadFAQ')
        }
    },[isRead])

    return (
        <div ref={refFAQ} className={showAnswer ? 'faqItem activeFAQ' : 'faqItem'}>
            <div ref={sectionQuetionRef} className={showAnswer ? 'sectionQuestion activeQuestion' : 'sectionQuestion'}
                onClick={() => handleShowAnswer()}
            >
                <img alt='arrow menu' src={arrow} className={showAnswer ? 'rotateImage' : null} />
                {dateTime ? <p className='dateTimeInFAQ'>{convertDateTime(dateTime)}</p> : null}
                <p ref={refPQuestion}>{question}</p>
            </div>
            <section className='sectionAnswer'>
                <p className='pb-2' ref={refPAnswer}>{answer}</p>
            </section>
        </div>
    );
}

export default FAQItem;