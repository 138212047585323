export const setReadData = array => {
    return async (dispatch, getState) => {

        const market = [...getState().marketCoins]
        const _Assets = array;
        const result = _Assets.reduce((ass, cur) => {
            const store = market.find(({coin}) => coin === cur.coin)
            if (store) {
                store.tomanBuying = cur.tomanBuying
                store.tomanSelling = cur.tomanSelling
                store.percentChange = cur.percentChange
                ass.push(store)
            }
            return ass
        },[])

        let temp = market.concat(result)
        temp = [...new Set([...market, ...result])]

        // console.log(temp)

        // await dispatch({type: 'INIT_REAL_DATA', payload: [...array]})
        await dispatch({type: 'INIT_REAL_DATA', payload: [...temp]})
    }
}