import React, {  useState } from 'react';
import ButtonForm from '../../components/ButtomForm/ButtomForm';
import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import './forgotPasswordOPT.css'
import { setDigit } from './../../redux/actions/digitActions';
import { toast } from 'react-toastify';
import axios from 'axios';
import { forgotPasswordConfirm, forgotPassword } from './../../services/userService'
import { Spinner } from 'reactstrap';
import SetNewPassword from '../SetNewPassword/SetNewPassword';
import ShowTimer from "../../components/ShowTimer/ShowTimer";
import handleErrors from "../../utils/handleErrors";

const ForgotPasswordOPT = ({ goForgotPassword, userEmail = 'mehdifarshbaf92@gmail.com' }) => {

    const [isDisable, setIsDisable] = useState(false)

    const [sendAgain, setSendAgain] = useState(false)
    const [numberrr, setNumberrr] = useState(['', '', '', '', '', ''])
    const [showConfirm, setShowConfirm] = useState(true)

    const dispatch = useDispatch()

    let codeNumber = ''
    const numOfFields = 6;

    const handleTextChange = (e, index) => {
        const {maxLength, value, name} = e.target;
        const [fieldName, fieldIndex] = name.split("-");
        let temp = [...numberrr]
        temp[index] = value;
        setNumberrr(temp)
        codeNumber = temp
        dispatch(setDigit(numberrr.join().replaceAll(',', '')))
        if (value.length >= maxLength) {
            if (parseInt(fieldIndex, 10) < numOfFields) {
                const nextSibling = document.querySelector(
                    `input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`
                );
                if (nextSibling !== null) {
                    nextSibling.focus();
                    return
                }
            }
        }
        if (value === '') {
            const previousSibling = document.querySelector(
                `input[name=ssn-${parseInt(index)}]`
            );
            if (previousSibling !== null) {
                previousSibling.focus();
                return
            }
        }

    }

    const handleConfirm = async () => {
        const code = numberrr.join('')

        const obj = {
            email: userEmail.email,
            code: numberrr.join('')
        }
        if (code.length > 5){
            setIsDisable(true)
            try {
                const { data } = await forgotPasswordConfirm(obj)
                if (data.type === 'error') {
                    toast.error(`${data.message}`)
                    setIsDisable(false)
                } else if (data.type === 'success') {
                    toast.success(`${data.message}`)
                    setIsDisable(false)
                    localStorage.setItem("token", data.data.token)
                    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
                    setShowConfirm(false)
                }
            } catch (ex) {
                setIsDisable(false)
                handleErrors(ex)
            }
        }else{
            toast.error('لطفا کد را کامل وارد کنید')
        }

    }

    const handleSendAgain = async () => {
        setIsDisable(true)
        try {
            const { data } = await forgotPassword(userEmail)
            if (data.type === 'success') {
                setIsDisable(false)
                toast.success(`${data.message}`, { position: 'top-right', closeOnClick: true })
                setSendAgain(false)
            }
        } catch (ex) {
            setIsDisable(false)
            toast.error(`${ex.data.errors.email[0]}`, { position: 'bottom-right', closeOnClick: true })
        }
    }


    return (
        <>
            {showConfirm ?
                <div className='forgotPasswordOPT'>
                    <div className='containerForgotPasswordOPT'>
                        <p className='titleInForm'>تایید آدرس ایمیل</p>
                        <div className='insideForm'>
                            <p className='pInRegisterOPT'>کد شش رقمی ارسال شده به ارسال ایمیل را وارد کنید</p>
                            <div className='inputDigit6'>
                                <div className='containerInputCodeOPT'>
                                    {numberrr.map((_, index) =>
                                        <input key={index} maxLength="1" onChange={(e) => handleTextChange(e, index)} name={`ssn-${index + 1}`} className='inputCodeOPt' type="number" />
                                    )
                                    }
                                </div>
                            </div>
                            {!sendAgain ? <ShowTimer marginTop='-16px' setSendAgain={setSendAgain}/> : null}
                            {sendAgain ? <ButtonForm isDis={isDisable} funcClick={handleSendAgain} marginBottom={16}>
                                {isDisable === false ? 'ارسال مجدد کد' :
                                    <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                             variant="secondary"/>
                                }
                            </ButtonForm> : null}
                            {!sendAgain?<ButtonForm isDis={isDisable} funcClick={() => handleConfirm()} marginBottom={37}>
                                {isDisable === false ? 'ثبت' :
                                    <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                             variant="secondary"/>
                                }
                            </ButtonForm>:null}
                        </div>
                    </div>
                </div>
                : <SetNewPassword email={userEmail.email} />}
        </>
    );
}

export default withRouter(ForgotPasswordOPT);