import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { reducer } from './../reducers/reducers';
import { setOptions } from "../actions/optionsActions";
import { initMarketCoins } from "../actions/marketCoinsActions";

export const store = createStore(
    reducer,
    compose(applyMiddleware(thunk),
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
)

store.dispatch(setOptions())
store.dispatch(initMarketCoins())