import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom'

import './navbar.css'
import DialogBuySell from './../../dialogs/DialogBuySell/DialogBuySell';
import { setCoin, setCoinWallet } from "../../redux/actions/marketCoinsActions";
import { setMarketId } from "../../redux/actions/marketIdAction";

const Navbar = (props) => {

    const [dialogBuySell, setDialogBuySell] = useState(false)
    const dispatch = useDispatch()
    const openSellBuy = () => {
        dispatch(setCoin(1))
        dispatch(setMarketId(1))
        setDialogBuySell(true)
    }
    const closeSellBuy = () => setDialogBuySell(false)

    const login = useSelector(state => state.login)
    return (
        <nav className='navsss'>
            <DialogBuySell fromNav={true} showDialog={dialogBuySell} closeDialog={closeSellBuy} />
            <ul>
                <li className='reg14 list-item'>
                    <a target='_blank' className='linklink' href='http://blog.6.vistadev.ir'>بلاگ</a>
                </li>
                <li onClick={() => props.history.push('/aboutus')} className='reg14 list-item'>درباره ما</li>
                <li onClick={() => props.history.push('/howtouse')} className='reg14 list-item'>راهنمای استفاده</li>
                <li onClick={() => props.history.push('/faq')} className='reg14 list-item'>سوالات متدوال</li>
                <li onClick={() => props.history.push('/markets')} className='reg14 list-item'>رمز ارزها</li>
                <li onClick={() => props.history.push('/')} className='reg14 list-item'>صفحه اصلی</li>
                {login ? <li className='reg14 list-item lastItemNav' onClick={() => openSellBuy()}>معامله</li> : null}
            </ul>
        </nav>
    );
}

export default withRouter(Navbar);