import React, { useState } from 'react';
import ButtonForm from './../../components/ButtomForm/ButtomForm';
import { withRouter } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import ErrorMessage from './../../components/ErrorMessage/ErrorMessage';
import './setNewPassword.css'
import { Spinner } from 'reactstrap';
import { setPassword } from './../../services/userService';
import { toast } from 'react-toastify';
import CustomInput from "../../components/CustomInput/CustomInput";
import handleErrors from "../../utils/handleErrors";

const validationSchema = Yup.object().shape({
    password: Yup.string().required().matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
        "باید حداقل شامل 8 کاراکتر و حروف کوچک و بزرگ و اعداد باشد")
})


const SetNewPassword = ({ history, email }) => {

    const [isDisable, setIsDisable] = useState(false)

    const handleSetNewPassword = async (values) => {
        setIsDisable(true)
        const objData = {
            email: email,
            password: values.password,
            password_confirmation: values.password,
            token: localStorage.getItem('token')
        }
        try {
            const { data } = await setPassword(objData)
            if (data.type === 'success') {
                setIsDisable(false)
                toast.success(`${data.message}`)
                history.replace('/login')
            } else if (data.type === 'error') {
                toast.error(`${data.message}`)
                setIsDisable(false)
            }
        } catch (ex) {
            handleErrors(ex)
            setIsDisable(false)
        }
    }

    return (
        <>
            <Formik
                initialValues={{ password: '' }}
                validationSchema={validationSchema}
                onSubmit={values => handleSetNewPassword(values)}
            >
                {({ handleChange, handleSubmit, values, errors,setFieldTouched,touched }) => (
                    <div className='setNewPassword'>
                        <div className='containerSetNewPassword'>
                            <p className='titleInForm'>تعیین رمز عبور</p>
                            <div className='insideForm'>
                                <p className='pInForgotPassword reg14'>رمز عبور جدید خود را وارد کنید.</p>
                                <form>
                                    <CustomInput inputType='password' textAlignLeft={true} placeholder='رمز عبور'
                                                 handleChange={handleChange('password')} value={values.password}
                                                 handleBlur={() => setFieldTouched('password')} marginBottom='16px'
                                    />
                                    <ErrorMessage error={errors.password} visible={touched.password} />
                                    <ButtonForm isDis={isDisable} funcClick={handleSubmit} type='submit' marginBottom='16px' >
                                        {isDisable === false ? 'ثبت' :
                                            <Spinner size={'sm'} className='invoice-list-wrapper' animation="border" variant="secondary" />
                                        }
                                    </ButtonForm>
                                </form>
                            </div>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
}

export default withRouter(SetNewPassword);