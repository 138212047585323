import React, {useEffect, useState} from 'react';
import parse from 'html-react-parser'

import './rules.scss'
import {getPageContent} from "../../services/pagesService";
import Loading from "../../components/Loading/Loading";

const Rules=()=>{
    const [getPolicy, setPolicy] = useState('')
    const [loadingPolicy, setLoadingPolicy] = useState(false)

    const getDataPolicy = async () => {
        setLoadingPolicy(true)
        try {
            const {data} = await getPageContent('policy')
            if(data.type ==='success'){
                setPolicy(data.data[0].content)
            }
            setLoadingPolicy(false)
        } catch (ex) {
            console.log(ex)
            setLoadingPolicy(false)
        }
    }
    useEffect(() => {
        document.title = 'ویستا  || قوانین و مقررات'
        getDataPolicy()
    },[])
    return(
        <section className='howToUse'>
            <div className='insideHowToUse'>
                {loadingPolicy  ? <Loading/> :
                    <>
                        <p className='subTitleHowToUse'>قوانین و مقررات</p>
                        <div className='parentParagraphsPolicy'>
                            {parse(getPolicy)}
                        </div>
                        <div className='endPHowToUse'>
                        </div>
                    </>
                }

            </div>
        </section>
    )
}
export default Rules