import React, {useEffect, useState} from 'react';
import FAQItem from './../../components/FAQItem/FAQItem';
import {getNotifications} from "../../services/notificationsService";
import Loading from "../../components/Loading/Loading";
import './notifications.scss'
import axios from "axios";

const Notification = () => {

    const [loading, setLoading] = useState(false)
    const [notifications, setNotifications] = useState([])

    const getNotificationsFromBackend = async () => {
        setLoading(true)
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
            const {data} = await getNotifications()
            if (data.type === 'success') {
                setNotifications(data.data)
                setLoading(false)
            }
        } catch (ex) {
            console.log(ex)
            setLoading(false)
        }
    }

    useEffect(() => {
        document.title = 'پنل کاربری || اعلانات'
        getNotificationsFromBackend()
    }, [])

    return (
        <section className='sectionNotifications'>
            {loading ? <Loading/> :
                (notifications.length > 0 ? notifications.map(notification => (
                            <FAQItem
                                question={notification.title}
                                answer={notification.body}
                                // dateTime={notification.dateTime}
                                id={notification.id}
                                dateTime={notification.created_at}
                                isRead={notification.is_read}/>
                        )) :
                        <p className='alertNotifications'>هنوز اعلانی برای شما ثبت نشده است.</p>
                )
            }

        </section>
    );
}

export default Notification;