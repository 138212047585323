import React, {useEffect, useState} from 'react';

import Features from './../../components/Features/Features';
import Info from './../../components/Info/Info';
import parse from "html-react-parser";

import {getPageContent} from "../../services/pagesService";
import Loading from "../../components/Loading/Loading";
import handleErrors from "../../utils/handleErrors";
import './aboutUs.scss'
const AboutUs = () => {

    const [aboutUs, setAboutUs] = useState('')
    const [loading, setLoading] = useState(false)

    const getDataAboutUs = async () => {
        setLoading(true)
        try {
            const {data} = await getPageContent('about_us')
            setAboutUs(data.data[0].content)
            setLoading(false)
        } catch (ex) {
            handleErrors(ex)
            setLoading(false)
        }
    }

    useEffect(() => {
        document.title = 'ویستا  || درباره ما'
        getDataAboutUs()
    },[])
    return (
        <section className='aboutUs'>
            <p className='titleHomePage'>درباره صرافی <span className='spanTitleAboutUs'>ویستا</span></p>
            <div className='insideAboutUs'>
                {loading ? <Loading/> : <p className='pTopAboutUs'>{parse(aboutUs)}</p>}
            </div>
            <Features/>
            <Info marginBottom='56px'/>
        </section>
    );
}

export default AboutUs;