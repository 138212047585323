import React, {useEffect} from 'react';
import {DialogContent, DialogOverlay} from '@reach/dialog';
import {useState} from 'react';
import {Formik} from 'formik'
import * as Yup from 'yup'
import './depositDialog.scss'
import {useSelector} from 'react-redux';
import ButtonForm from './../../components/ButtomForm/ButtomForm';
import {Spinner} from "reactstrap";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import ComponentMenu from "../../components/ComponentMenu/ComponentMenu";
import {toast} from "react-toastify";
import {depositOnline} from "../../services/depositWithdrawService";
import {withRouter} from 'react-router-dom'
import InputPrice from "../../components/InputPrice/InputPrice";
import handleErrors from "../../utils/handleErrors";

const validationSchema = Yup.object().shape({
    amount: Yup.number().required('پر کردن این فیلد الزامی می باشد.').min(1000, 'نباید کمتر از 1000 تومان باشد').max(50000000,'سقف مجاز کمتر از 50 میلیون است')
})


const DepositDialog = ({showDialog, closeDialog}) => {

    const acceptedAccounts = useSelector(state => state.acceptedAccounts)


    const [getIdDebit, setIdDebit] = useState(-1)

    const [isDisable, setIsDisable] = useState(false)

    const handleSubmit = async (values) => {
        setIsDisable(true)
        if (getIdDebit > -1) {
            const obj = {
                payment_method: 'ONLINE',
                amount: values.amount,
                account_id: acceptedAccounts[getIdDebit].value,
                gateway: 'zarinpal',
                coin_id: 1
            }
            try {
                const {data} = await depositOnline(obj)
                if (data.type === 'success') {
                    setIsDisable(false)
                    window.location.href = data.data.link
                    toast.success(`${data.message}`, {position: 'top-right', closeOnClick: true})
                    closeDialog()
                }
            } catch (ex) {
                setIsDisable(false)
                console.log(ex)
                handleErrors(ex)
            }
        } else {
            setIsDisable(false)
            toast.error('لطفا کارت بانکی رو انتخاب کنید.')
        }
    }


    useEffect(() => {
        setIdDebit(-1)
        setIsDisable(false)
        return () => setIdDebit(-1)
    }, [showDialog])

    return (
        <DialogOverlay onDismiss={closeDialog} isOpen={showDialog}>
            <DialogContent className='containerNewQuestion bgchangeDialog'>
                <div className='insideDeposit'>
                    <p className='titleDeposit' >واریز تومانی</p>
                    <p className='textInDialogDeposit'>
                        جهت افزایش اعتبار کیف پول تومان خود با استفاده از کارت‌های بانکی عضو شبکه شتاب و از طریق درگاه
                        پرداخت، حتما باید از کارت‌های بانکی به نام خودتان که در پروفایلتان ثبت و تأیید شده است استفاده
                        نمایید.
                    </p>
                    <Formik initialValues={{amount: ''}}
                            onSubmit={(values) => handleSubmit(values)}
                            validationSchema={validationSchema}
                    >
                        {({values, setFieldTouched, touched, errors, handleChange, handleSubmit}) => (
                            <>

                                <InputPrice caption={true}  placeholder='(تومان)مبلغ واریزی'
                                              marginBottom='18px'
                                             onChange={handleChange('amount')}
                                             onBlur={() => setFieldTouched('amount')}/>
                                <ErrorMessage error={errors.amount} visible={touched.amount}/>
                                <ComponentMenu enableSpan={true} title='انتخاب کارت بانکی' setID={setIdDebit}
                                               list={acceptedAccounts}
                                               placeholder={getIdDebit < 0 ? 'انتخاب کارت بانکی' : acceptedAccounts[getIdDebit].title}
                                               marginBottom='16px'/>
                                <ButtonForm isDis={isDisable} funcClick={handleSubmit} marginBottom='24px'>
                                    {isDisable === false ? 'انتقال به درگاه پرداخت' :
                                        <Spinner size={'sm'} className='invoice-list-wrapper' animation="border"
                                                 variant="secondary"/>
                                    }
                                </ButtonForm>
                            </>
                        )}
                    </Formik>
                </div>
            </DialogContent>
        </DialogOverlay>
    );
}
export default withRouter(DepositDialog);