import React, {useEffect, useState} from 'react';
import parse from 'html-react-parser'
import {getPageContent} from './../../services/pagesService';
import './howToUse.scss'
import Loading from "../../components/Loading/Loading";

const HowToUse = () => {

    const [getHelp, setHelp] = useState('')
    const [loadingHelp, setLoadingHelp] = useState(false)

    const getDataHelp = async () => {
        setLoadingHelp(true)
        try {
            const {data} = await getPageContent('help')
            if(data.type === 'success'){
                setHelp(data.data[0].content)
            }
            setLoadingHelp(false)
        } catch (ex) {
            console.log(ex)
            setLoadingHelp(false)
        }
    }


    useEffect(() => {
        document.title = 'ویستا  || راهنمای استفاده'

        getDataHelp()
    },[])
    return (
        <section className='howToUse'>
            <div className='insideHowToUse'>
                <p className='titleHowToUse'>راهنمای استفاده</p>
                { loadingHelp ? <Loading/> :
                    <>
                        <div className="parentHowToUse">
                            <p>
                                {parse(getHelp)}
                            </p>
                        </div>
                        <div className='endPHowToUse'>
                        </div>
                    </>
                }

            </div>
        </section>
    );
}

export default HowToUse;