import React, { useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom'
import * as FaIcons from "react-icons/fa";
import BoxBtnLoginRegister from '../BoxBtnLoginRegister/BoxBtnLoginRegister';
// import logo from './../../assets/img/logo.svg'
import logo from './../../assets/img/ArzTrading_logo-02.svg'
import logoVista from './Logo.jpeg'
// import logo from './../../assets/img/ArzTrading_logo-02.png.png'
import './topNav.scss'
import Navbar from './../Navbar/Navbar';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { userLogout } from '../../redux/actions/loginActions';

import { logoutUser } from "../../services/userService";
import { toast } from "react-toastify";

const TopNavbar = (props) => {

    const [sidebar, setSidebar] = useState(false)
    const showSidebar = () => setSidebar(!sidebar)
    const login = useSelector(state => state.login)
    const refSidebarMobile = useRef()
    const dispatch = useDispatch()

    useEffect(() => {
        const checkClickOut = e => {
            if (sidebar && refSidebarMobile.current && !refSidebarMobile.current.contains(e.target)) {
                setSidebar(false)
            }
        }
        document.addEventListener('click', checkClickOut)
        return () => document.removeEventListener('click', checkClickOut)
    }, [sidebar])

    const handleLogout = async () => {
        try {
            const { data } = await logoutUser()
            if (data.type === 'success') {
                toast.success(`${data.message}`, { position: 'top-right', closeOnClick: true })
                dispatch(userLogout())
                localStorage.removeItem('token')
                props.history.replace('/')
            }

        } catch (ex) {
            console.log(ex.message)
        }

    }

    return (
        <nav>
            <section className='myNavbar'>
                <div className='insideNavbar'>
                    {login ?
                        <button className='btnPanelUser' onClick={() => props.history.replace('/account')}>پنل کاربری</button>
                        :
                        <BoxBtnLoginRegister />
                    }
                    <FaIcons.FaBars className='btnBar' onClick={showSidebar} />
                    <nav ref={refSidebarMobile} className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                        {login ?
                            <div className='menuItemSidebar' onClick={() => { setSidebar(false); props.history.replace('/account') }}>
                                <NavLink className='linkIntoSideBar' to='/account'><span>پنل کاربری</span></NavLink>
                            </div> : <div className='containerLoginRegisterSidebar'>
                                <div className='btnLoginSide' onClick={() => { props.history.replace('/login'); setSidebar(false) }}>ورود</div>
                                <div className='btnRegistterSide' onClick={() => { props.history.replace('/register'); setSidebar(false) }} >ثبت نام</div>
                            </div>}
                        <div className='menuItemSidebar' onClick={() => { setSidebar(false); props.history.replace('/') }}>
                            <NavLink className='linkIntoSideBar' to='/'><span>صفحه اصلی</span></NavLink>
                        </div>
                        <div className='menuItemSidebar' onClick={() => setSidebar(false)}>
                            <a target='_blank' className='linkIntoSideBar' href='http://blog.6.vistadev.ir'>بلاگ</a>
                        </div>
                        <div className='menuItemSidebar' onClick={() => { setSidebar(false); props.history.replace('/aboutus') }}>
                            <NavLink className='linkIntoSideBar' to='/aboutus'><span>درباره ما</span></NavLink>
                        </div>
                        <div className='menuItemSidebar' onClick={() => { setSidebar(false); props.history.replace('/howtouse') }}>
                            <NavLink className='linkIntoSideBar' to='/howtouse'><span>راهنمای استفاده</span></NavLink>
                        </div>
                        <div className='menuItemSidebar' onClick={() => { setSidebar(false); props.history.replace('/faq') }}>
                            <NavLink className='linkIntoSideBar' to='/faq'><span>سوالات متداول</span></NavLink>
                        </div>
                        <div className='menuItemSidebar' onClick={() => { setSidebar(false); props.history.replace('/markets') }}>
                            <NavLink className='linkIntoSideBar' to='/markets'><span>رمزارزها</span></NavLink>
                        </div>
                        {login ? <div className='menuItemSidebar' onClick={() => { setSidebar(false); props.history.replace('/') }}>
                            <NavLink className='linkIntoSideBar' to='/'><span>معامله</span></NavLink>
                        </div> : null}
                        {login ? <div className='menuItemSidebar' onClick={() => handleLogout()}>
                            {/*<NavLink className='linkIntoSideBar' to='/'><span>خروج</span></NavLink>*/}
                            <div className='linkIntoSideBar' ><span>خروج</span></div>
                        </div> : null}

                    </nav>
                    <Navbar />
                    <img src={logoVista} alt='' onClick={() => props.history.replace('/')} className='logoNav' />
                </div>
            </section>
        </nav>
    );
}

export default withRouter(TopNavbar);