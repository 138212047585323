import config from './config.json'

import http from './httpService'

export const getPageContent = name => {
    return http.get(`${config.coinbaseapi}/pages?name=${name}`)
}
export const getPosts = () => {
    // return http.get('http://arztrading.com/blog/wp-json/wp/v2/posts?per_page=8&context=embed&page=1&_embed')
    return http.get('http://blog.6.vistadev.ir/wp-json/wp/v2/posts?per_page=8&context=embed&page=1&_embed')
}